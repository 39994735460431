import React from "react";
import theme from "theme";
import { Theme, Link, Image, Text, LinkBox, Box, Section, Strong, Hr, Span, Button, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"buy MTN Mi-Fi, 4G & 5G Routers and other products in Lagos, Nigeria - www.systreame.com Streame store "} />
			<meta property={"og:title"} content={"Streame online store"} />
			<meta property={"og:description"} content={"MTN Partner Store - MTN Mi-Fi, 4G Cat4 & Cat6 Routers, 5G Routers and other products - Super fast MTN Broadband Internet Devices"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09:36:51.265Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09:36:51.265Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09:36:51.265Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09:36:51.265Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09:36:51.265Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09:36:51.265Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09:36:51.265Z"} />
			<meta name={"msapplication-TileColor"} content={"#F2EFEA"} />
		</Helmet>
		<Section sm-padding="8px 0 8px 0" quarkly-title="Header-6" padding="24px 0px 24px 0px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				justify-content="space-between"
				max-width="100%"
				width="100%"
				padding="0px 24px 0px 24px"
			/>
			<Box
				display="flex"
				padding="12px 0"
				justify-content="center"
				align-items="flex-start"
				flex-direction="row"
				width="20%"
				sm-width="50%"
				sm-align-items="center"
				sm-flex-direction="row"
				sm-justify-content="flex-start"
				md-width="50%"
				lg-width="20%"
				md-justify-content="flex-start"
				md-order="-1"
			>
				<LinkBox flex-direction="row" href="/index" display="flex" grid-gap="12px">
					<Image
						src="https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09:36:51.265Z"
						display="block"
						width="200px"
						height="59px"
						padding="0px 0px 0px 0px"
						margin="0px 0px 0px 250px"
						srcSet="https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09%3A36%3A51.265Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09%3A36%3A51.265Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09%3A36%3A51.265Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09%3A36%3A51.265Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09%3A36%3A51.265Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09%3A36%3A51.265Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/cover1.png?v=2024-06-05T09%3A36%3A51.265Z&quality=85&w=3200 3200w"
						sizes="(max-width: 320px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,(max-width: 320px) 100vw,(min-width: 1280px) 100vw,100vw"
					/>
					<Text
						margin="0 0 0 0"
						md-margin="0px 0 0px 0"
						text-align="left"
						font="normal 400 22px/1.5 --fontFamily-googleOutfit"
						sm-margin="0px 0 0px 0"
						display="block"
						width="240px"
						padding="0px 0px 0px 0px"
					>
						STREAME Online Store
					</Text>
				</LinkBox>
			</Box>
			<Box
				display="flex"
				padding="12px 0 12px 0px"
				justify-content="flex-end"
				align-items="flex-start"
				flex-direction="row"
				width="50%"
				sm-width="50%"
				sm-align-items="center"
				sm-flex-direction="row"
				sm-justify-content="flex-start"
				md-width="50%"
				lg-width="40%"
				md-justify-content="flex-start"
				md-display="none"
				margin="0px 0px 0px 0px"
			>
				<Link
					font="--base"
					text-decoration-line="initial"
					margin="0px 30px 0px 16px"
					background="--color-darkL1"
					border-radius="8px"
					md-margin="0px 0px 0px 14px"
					color="#ffffff"
					padding="6px 12px 7px 12px"
					md-order="1"
					id="whatsapp"
					href="https://wa.me/2348033320581"
				>
					WhatsApp
				</Link>
				<Link
					font="--base"
					text-decoration-line="initial"
					margin="0px 10px 0px 16px"
					background="--color-darkL1"
					border-radius="8px"
					md-margin="0px 0px 0px 14px"
					href="https://t.me/adubi1"
					color="#ffffff"
					padding="6px 12px 7px 12px"
					md-order="1"
					id="telegram"
				>
					Telegram
				</Link>
				<Link
					border-radius="8px"
					href="mailto:systreame@gmail.com"
					text-decoration-line="initial"
					border-style="solid"
					sm-padding="5px 10px 5px 10px"
					md-padding="5px 10px 5px 10px"
					sm-margin="10px 0px 10px 0px"
					font="--base"
					color="--darkL1"
					margin="0px 0px 0px 34px"
					md-margin="10px 0px 0px 0px"
					border-width="2px"
					padding="4px 12px 5px 12px"
					border-color="--color-darkL1"
					id="contact"
				>
					Contact
				</Link>
			</Box>
		</Section>
		<Section
			padding="150px 0 150px 0"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Cat4%20MF293N%20Routers....jpg?v=2024-06-05T09:42:47.905Z) 0% 0% /cover repeat scroll padding-box"
			sm-padding="80px 0 80px 0"
			quarkly-title="Product-7"
			height="800px"
		>
			<Box min-width="100px" min-height="100px" display="flex" justify-content="flex-end">
				<Box
					min-width="100px"
					min-height="100px"
					width="400px"
					padding="50px 35px 50px 35px"
					display="flex"
					align-items="center"
					flex-direction="column"
					md-padding="35px 35px 35px 35px"
					opacity="1"
					margin="-110px 600px 180px 0px"
				>
					<Text margin="-10px 0px 20px 0px" font="normal 600 60px/1.2 --fontFamily-sansHeavy" text-align="center">
						Ultra-fast.{" "}
						<br />
						Affordable.{" "}
						<br />
						Unbeatable.
					</Text>
					<Text margin="220px 0px 35px 0px" text-align="center" font="normal 400 24px/1.5 --fontFamily-googleRoboto">
						<Strong
							font="700 28px/36px --fontFamily-googleOutfit"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							min-width="0px"
						>
							Enjoy the fast and reliable internet connection on MTN Broadband
						</Strong>
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-6" xs-font="normal 400 10px/1.5 --fontFamily-sans" md-font="10px sans-serif">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Our Products
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--darkL1"
				padding="0px 280px 0px 280px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				WE OFFER A WIDE ARRAY OF MTN PRODUCTS AND SERVICES
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
				align-self="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
				height="700px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Image
						src="https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20ZLT%20S20%20Cat%204%20Router.jpg?v=2024-06-05T09:42:47.935Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20ZLT%20S20%20Cat%204%20Router.jpg?v=2024-06-05T09%3A42%3A47.935Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20ZLT%20S20%20Cat%204%20Router.jpg?v=2024-06-05T09%3A42%3A47.935Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20ZLT%20S20%20Cat%204%20Router.jpg?v=2024-06-05T09%3A42%3A47.935Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20ZLT%20S20%20Cat%204%20Router.jpg?v=2024-06-05T09%3A42%3A47.935Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20ZLT%20S20%20Cat%204%20Router.jpg?v=2024-06-05T09%3A42%3A47.935Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20ZLT%20S20%20Cat%204%20Router.jpg?v=2024-06-05T09%3A42%3A47.935Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20ZLT%20S20%20Cat%204%20Router.jpg?v=2024-06-05T09%3A42%3A47.935Z&quality=85&w=3200 3200w"
						sizes="(max-width: 320px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,(max-width: 320px) 100vw,(min-width: 1280px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						background="#ffdd61"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							MTN 4G Cat4 (Standard) Routers
						</Text>
						<Text
							margin="0px 0px 25px 0px"
							font="normal 300 16px/1.5 --fontFamily-serifGaramond"
							lg-margin="0px 0px 18px 0px"
							flex="1 1 0%"
							text-align="justify"
						>
							MTN 4G LTE Cat4  routers supports download speeds up to 150Mbps and upload speeds up to
50Mbps. Connects up to 32 devices simultaneously while supporting WiFi 802.11b/g/n, 2x2 MIMO and 2 SMA
connectors for external antennas
							<br />
							<br />
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Strong>
									Free 50GB Data on Activation
								</Strong>
								<br />
							</Span>
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
						>
							<Button background="--color-darkL1" border-radius="4px" href="https://wa.me/2348033320581">
								Chat
							</Button>
							<Text margin="0px -20px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" text-decoration-line="line-through">
								N
							</Text>
							<Text margin="0px 10px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond">
								14,625
							</Text>
							<Text margin="0px -20px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond">
								save
							</Text>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" text-decoration-line="line-through">
								N375
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Cat6%20ZLT%20S50%20Router.jpg?v=2024-06-05T09:42:48.102Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Cat6%20ZLT%20S50%20Router.jpg?v=2024-06-05T09%3A42%3A48.102Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Cat6%20ZLT%20S50%20Router.jpg?v=2024-06-05T09%3A42%3A48.102Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Cat6%20ZLT%20S50%20Router.jpg?v=2024-06-05T09%3A42%3A48.102Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Cat6%20ZLT%20S50%20Router.jpg?v=2024-06-05T09%3A42%3A48.102Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Cat6%20ZLT%20S50%20Router.jpg?v=2024-06-05T09%3A42%3A48.102Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Cat6%20ZLT%20S50%20Router.jpg?v=2024-06-05T09%3A42%3A48.102Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Cat6%20ZLT%20S50%20Router.jpg?v=2024-06-05T09%3A42%3A48.102Z&quality=85&w=3200 3200w"
						sizes="(max-width: 320px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,(max-width: 320px) 100vw,(min-width: 1280px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
						background="#ffdd61"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							MTN 4G Cat6 (Premium) Routers
						</Text>
						<Text
							margin="0px 0px 25px 0px"
							font="normal 300 16px/1.5 --fontFamily-serifGaramond"
							lg-margin="0px 0px 18px 0px"
							flex="1 1 0%"
							text-align="justify"
						>
							MTN’s Broadband 4G+  offers you amazing speeds for seamless upload or download of
large files, online video streaming and lots more. Ideal for SMEs, Home users and Heavy data
users (video streaming, gaming, etc.).
							<br />
							<br />
							<Strong>
								Free 120GB Data on Activation
							</Strong>
							{" "}
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
						>
							<Button background="--color-darkL1" border-radius="4px" href="https://wa.me/2348033320581">
								Chat
							</Button>
							<Text margin="0px -20px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" text-decoration-line="line-through">
								N
							</Text>
							<Text margin="0px 10px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond">
								29,250
							</Text>
							<Text margin="0px -20px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond">
								save
							</Text>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" text-decoration-line="line-through">
								N750
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Mi-Fi.jpg?v=2024-06-07T14:57:50.640Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Mi-Fi.jpg?v=2024-06-07T14%3A57%3A50.640Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Mi-Fi.jpg?v=2024-06-07T14%3A57%3A50.640Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Mi-Fi.jpg?v=2024-06-07T14%3A57%3A50.640Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Mi-Fi.jpg?v=2024-06-07T14%3A57%3A50.640Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Mi-Fi.jpg?v=2024-06-07T14%3A57%3A50.640Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Mi-Fi.jpg?v=2024-06-07T14%3A57%3A50.640Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20Mi-Fi.jpg?v=2024-06-07T14%3A57%3A50.640Z&quality=85&w=3200 3200w"
						sizes="(max-width: 320px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,(max-width: 320px) 100vw,(min-width: 1280px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
						background="#ffdd61"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							MTN 4G LTE Mi-Fi
						</Text>
						<Text
							margin="0px 0px 25px 0px"
							font="normal 300 16px/1.5 --fontFamily-serifGaramond"
							lg-margin="0px 0px 18px 0px"
							flex="1 1 0%"
							text-align="justify"
						>
							MTN 4G LTE pocket size mobile internet hotspot Router that enables you connect up to 10 Wi-Fi devices at once. This MiFi is a high-speed broadband portable Wi-Fi device compatible with both 3G and 4G LTE networks, ideal for use on the go!{" "}
							<br />
							<br />
							<Strong>
								Free 30GB Data on Activation
							</Strong>
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
						>
							<Button background="--color-darkL1" border-radius="4px" href="https://wa.me/2348033320581">
								Chat
							</Button>
							<Text margin="0px -25px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" text-decoration-line="line-through">
								N{"  "}
							</Text>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond">
								9,750{"  "}
							</Text>
							<Text margin="0px -28px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond">
								save
							</Text>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" text-decoration-line="line-through">
								N250{"  "}
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Image
						src="https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%205G%20Router.png?v=2024-06-07T14:55:33.849Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%205G%20Router.png?v=2024-06-07T14%3A55%3A33.849Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%205G%20Router.png?v=2024-06-07T14%3A55%3A33.849Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%205G%20Router.png?v=2024-06-07T14%3A55%3A33.849Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%205G%20Router.png?v=2024-06-07T14%3A55%3A33.849Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%205G%20Router.png?v=2024-06-07T14%3A55%3A33.849Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%205G%20Router.png?v=2024-06-07T14%3A55%3A33.849Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%205G%20Router.png?v=2024-06-07T14%3A55%3A33.849Z&quality=85&w=3200 3200w"
						sizes="(max-width: 320px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,(max-width: 320px) 100vw,(min-width: 1280px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							MTN 5G Broadband Routers
						</Text>
						<Text
							margin="0px 0px 25px 0px"
							font="normal 300 16px/1.5 --fontFamily-serifGaramond"
							lg-margin="0px 0px 18px 0px"
							flex="1 1 0%"
							text-align="justify"
						>
							MTN 5G routers connects to MTN Broadband to access super-fast browsing. MTN
     Broadband 5G Router offers faster speed and performance than 3G & 4G. Connecting multiple devices (up to
     32) to the Router. Superior streaming, gaming, data
     download, and uploads. Wide Wi-Fi connection range.{" "}
							<br />
							<br />
							<Strong>
								Free 100GB Data on Activation
							</Strong>
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
						>
							<Button background="--color-darkL1" border-radius="4px" href="https://wa.me/2348033320581">
								Chat
							</Button>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" text-decoration-line="line-through">
								N
							</Text>
							<Text margin="0px 0px 0px -135px" font="normal 600 20px/1.5 --fontFamily-serifGaramond">
								60,000
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20EyeSyte%20Smart%20PTZ%20Camera.jpg?v=2024-06-05T09:42:47.939Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20EyeSyte%20Smart%20PTZ%20Camera.jpg?v=2024-06-05T09%3A42%3A47.939Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20EyeSyte%20Smart%20PTZ%20Camera.jpg?v=2024-06-05T09%3A42%3A47.939Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20EyeSyte%20Smart%20PTZ%20Camera.jpg?v=2024-06-05T09%3A42%3A47.939Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20EyeSyte%20Smart%20PTZ%20Camera.jpg?v=2024-06-05T09%3A42%3A47.939Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20EyeSyte%20Smart%20PTZ%20Camera.jpg?v=2024-06-05T09%3A42%3A47.939Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20EyeSyte%20Smart%20PTZ%20Camera.jpg?v=2024-06-05T09%3A42%3A47.939Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/MTN%20EyeSyte%20Smart%20PTZ%20Camera.jpg?v=2024-06-05T09%3A42%3A47.939Z&quality=85&w=3200 3200w"
						sizes="(max-width: 320px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,(max-width: 320px) 100vw,(min-width: 1280px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							MTN EyeSyte Smart PTZ Camera
						</Text>
						<Text
							margin="0px 0px 25px 0px"
							font="normal 300 16px/1.5 --fontFamily-serifGaramond"
							lg-margin="0px 0px 18px 0px"
							flex="1 1 0%"
							text-align="justify"
						>
							EyeSyte Pan-Tilt-Zoom (PTZ)
security camera  comes with a range of benefits over fixed security
cameras. Armed with motion tracking capability, the PTZ camera can move and
track subjects, enabling each camera to cover a wide zone. 1080P Full HD video quality, Day & Night vision. Get SMS and call notification alerts when there is an intrusion in your home or office
							<br />
							<br />
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
						>
							<Button background="--color-darkL1" border-radius="4px" href="https://wa.me/2348033320581">
								Chat
							</Button>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" text-decoration-line="line-through">
								N
							</Text>
							<Text margin="0px 0px 0px -140px" font="normal 600 20px/1.5 --fontFamily-serifGaramond">
								25,000
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/665f3a3b1d712a002334814d/images/mtn%20vehicle%20tracking.png?v=2024-06-07T17:18:46.325Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/mtn%20vehicle%20tracking.png?v=2024-06-07T17%3A18%3A46.325Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/mtn%20vehicle%20tracking.png?v=2024-06-07T17%3A18%3A46.325Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/mtn%20vehicle%20tracking.png?v=2024-06-07T17%3A18%3A46.325Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/mtn%20vehicle%20tracking.png?v=2024-06-07T17%3A18%3A46.325Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/mtn%20vehicle%20tracking.png?v=2024-06-07T17%3A18%3A46.325Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/mtn%20vehicle%20tracking.png?v=2024-06-07T17%3A18%3A46.325Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665f3a3b1d712a002334814d/images/mtn%20vehicle%20tracking.png?v=2024-06-07T17%3A18%3A46.325Z&quality=85&w=3200 3200w"
						sizes="(max-width: 320px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,(max-width: 320px) 100vw,(min-width: 1280px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							TraCar From MTN
						</Text>
						<Text
							margin="0px 0px 25px 0px"
							font="normal 300 16px/1.5 --fontFamily-serifGaramond"
							lg-margin="0px 0px 18px 0px"
							flex="1 1 0%"
							text-align="justify"
						>
							Vehicle tracking solution offers real-time tracking over map, Geo-fencing, Fuel level & consumption monitoring, Driver management (time, distance & speed monitoring), Automatic alerts for all features
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
						>
							<Button background="--color-darkL1" border-radius="4px" href="https://wa.me/2348033320581">
								Chat
							</Button>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" text-decoration-line="line-through">
								N
							</Text>
							<Text margin="0px 0px 0px -130px" font="normal 600 20px/1.5 --fontFamily-serifGaramond">
								115,000
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline1" margin="0 0 8px 0">
							FAQs
						</Text>
						<Text as="p" font="--lead" margin="0" color="--greyD2">
							Need answers? We got’em
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="normal 700 60px/1.2 --fontFamily-googlePoppins" margin="12px 0">
									How do I place an order?
								</Text>
								<Text
									as="p"
									font="--base"
									margin="12px 0"
									color="--greyD2"
									text-align="justify"
								>
									Click on our social media (WhatsApp & Telegram) buttons at the top or the "Chat" button in our Products section to chat with our store.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									When will my order be processed?
								</Text>
							</Box>
							<Text
								as="p"
								font="--base"
								margin="12px 0"
								color="--greyD2"
								text-align="justify"
							>
								We process orders from Monday to Saturday. All orders made before 2pm WAT are dispatched same day by 3rd party bike delivery for locations within Lagos.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									What payment methods do you accept?
								</Text>
								<Text
									as="p"
									font="--base"
									margin="12px 0"
									color="--greyD2"
									text-align="justify"
								>
									We do not have a Checkout option on our website yet so we accept bank transfers upon delivery (at the point of collection). We do not accept cash.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Can I pick up my order?
								</Text>
								<Text
									as="p"
									font="--base"
									margin="12px 0"
									color="--greyD2"
									text-align="justify"
								>
									Customers can pickup their orders at our physical outlet or arrange preferred collection/pickup options.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 50px 0" background="--color-dark" quarkly-title="Footer-13" sm-padding="50px 0 50px 0">
			<Override slot="SectionContent" width="100% " height="100%" min-height="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 30px 100px 30px"
				background="linear-gradient(180deg,rgba(255, 255, 255, 0.2) 0.5%,rgba(255, 255, 255, 0.15) 99%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1537204319452-fdbd29e2ccc7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) 0% 0% /auto repeat scroll padding-box"
				display="flex"
				justify-content="space-around"
				align-items="center"
				margin="-90px 0px -50px 0px"
				md-flex-direction="column"
				md-align-items="stretch"
				md-margin="0px 0px 50px 0px"
				md-padding="30px 30px 30px 30px"
				sm-padding="20px 15px 20px 15px"
				height="350px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					md-margin="0px 0px 30px 0px"
					md-width="100%"
					sm-margin="0px 0px 18px 0px"
				>
					<Text
						margin="65px 0px 20px 0px"
						font="normal 400 42px/1.2 --fontFamily-sansHelvetica"
						color="--light"
						md-margin="0px 0px 15px 0px"
						sm-margin="0px 0px 12px 0px"
					>
						Get in touch
					</Text>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 300 20px/1.5 --fontFamily-sans">
						Visit us at{" "}
						<br />
						43 Shobowale Street, Off Akilo Road, Opposite Mario Fisheries, Agege, Lagos
						<br />
						2 Adedosu Street, Off Akilo Road, Agege, Lagos{" "}
					</Text>
					<Text margin="30px 0px 0px 0px" color="--light" font="normal 300 20px/1.5 --fontFamily-sans">
						We are open at
						<br />
						Monday to Friday (8:00 am – 5:30 pm) except Thursday (10:00 am opening)
						<br />
						Saturday (10:00 am – 4:30 pm){" "}
					</Text>
				</Box>
				<LinkBox
					flex-direction="row"
					padding="15px 25px 15px 25px"
					border-width="2px"
					border-style="solid"
					border-color="--color-light"
					background="rgba(247, 251, 255, 0.12)"
					sm-padding="10px 15px 10px 15px"
					href="tel:+2348033320581"
				>
					<Icon
						category="md"
						icon={MdPhone}
						size="24px"
						color="--light"
						margin="0px 8px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 500 20px/1.5 --fontFamily-sans" color="--light">
						+234 803 332 0581
					</Text>
				</LinkBox>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<meta place={"endOfHead"} rawKey={"666ac09d7e2c3e9a5c131a37"} />
			<meta place={"endOfHead"} rawKey={"666c08794730f522e5f71af1"} />
		</RawHtml>
	</Theme>;
});